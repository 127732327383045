<template>
    <div class="app flex-row align-items-center">

        <b-row style="max-width: 100%;padding-left:0px !important;padding-right: 0px; !important;margin: 0px;">


            <div v-if="paso == 1" id="principal" class="col-md-12">
                <mdb-row class="mt-5 align-items-center justify-content-start">
                    <div class="col-md-6">
                        <h4 class="demo-title"><strong><span style="cursor: pointer" @click="VolverPanel">Panel Administrativo</span> > <span @click="IrDelegacion" style="cursor: pointer"> Vídeos</span> > Nuevo </strong></h4>
                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-2">

                    </div>
                </mdb-row>

                <hr />

                <div style="padding: 10px"></div>


                <section>
                    <b-row style="padding: 20px">
                        <h4> Paso 1 : Detalles de el vídeo</h4>
                    </b-row>
                    <b-row style="padding: 20px">
                        <div class="col-md-6">
                            <div class="col-md-12">
                                <label for="Nombre de la Delegación" style="padding-top:15px">Título de el vídeo</label>
                                <input type="text" id="username"  v-model="video.nombre" class="form-control">
                            </div>
                            <div class="col-md-12">
                                <label for="Web de la delegación" style="padding-top:15px">Descripción de el vídeo</label><div class="md-form md-outline">
                                <textarea id="form75"  v-model="video.descripcion" class="md-textarea form-control" rows="3"></textarea>

                            </div>
                                <div class="col-md-12" style="padding-top:25px">
                                    <b-form-group
                                            label="Delegación"
                                            label-for="Delegación">
                                        <div id="selector">
                                            <select v-model="video.delegacion" :class="'form-control'">
                                                <option v-for="(item) in delegaciones" :value="item.id">
                                                    {{item.nombre}}
                                                </option>
                                            </select>
                                            <br>
                                            <br>
                                        </div>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="Nombre de la Delegación" style="padding-top:15px">Imagen de el vídeo</label>
                            <vue-dropzone ref="myVueDropzone" id="dropzone"  @vdropzone-file-added="uploadImage" :options="dropzoneOptions"></vue-dropzone>
                        </div>

                    </b-row>

                </section>

                <div style="padding: 10px"></div>
                <hr />

                <div style="padding: 10px"></div>
                <footer>
                    <mdb-btn style="float:right" color="primary" @click="IrPaso2" size="lg">Siguiente</mdb-btn>
                    <br />
                </footer>

                <div style="padding: 10px"></div>




            </div>

            <div v-if="paso == 2" id="principal2" class="col-md-12">
                <mdb-row class="mt-5 align-items-center justify-content-start">
                    <div class="col-md-6">
                        <h4 class="demo-title"><strong><span style="cursor: pointer" @click="VolverPanel">Panel Administrativo</span> > <span @click="IrDelegacion" style="cursor: pointer"> Vídeos</span> > Nuevo </strong></h4>
                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-2">

                    </div>
                </mdb-row>

                <hr />

                <div style="padding: 10px"></div>
                <section>
                    <b-row style="padding: 20px">
                        <h4> Paso 2 : Subir Vídeo</h4>
                        <div class="col-md-12">
                            <mdb-row>
                                <mdb-col md="12">
                                    <vue-dropzone ref="file" id="file"  @vdropzone-file-added="onChangeFileUpload" :options="dropzoneOptions2"></vue-dropzone>
                                </mdb-col>
                            </mdb-row>
                        </div>
                    </b-row>
                </section>

            </div>

        </b-row>

        <vue-cookie-accept-decline
                :ref="'myPanel1'"
                :elementId="'myPanel1'"
                :debug="false"
                :position="'bottom-left'"
                :type="'floating'"
                :disableDecline="true"
                :transitionName="'slideFromBottom'"
                :showPostponeButton="false"
                @status="cookieStatus"
                @clicked-accept="cookieClickedAccept"
                @clicked-decline="cookieClickedDecline">

            <!-- Optional -->
            <div slot="postponeContent">
                &times;
            </div>

            <!-- Optional -->
            <div slot="message">
                Agustinos.tv cumple con la normativa de cookies y privacidad.<a href="https://agustinostv-front.archive.tornadocore.es/proteccion_de_datos.pdf" target="_blank">Leer más </a>
            </div>


            <!-- Optional -->
            <div slot="acceptContent">
                ACEPTAR
            </div>
        </vue-cookie-accept-decline>
    </div>



</template>

<script>

    import { mdbBreadcrumb,
        mdbBreadcrumbItem,
        mdbIcon,
        mdbRow,
        mdbContainer,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbCol,
        mdbView,
        mdbBtn,
        mdbMask,
        mdbCardGroup,  } from 'mdbvue';
    import ApiService from '@/services/api';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import axios from 'axios';

    export default {
        name: 'Nuevo Vídeo',
        components: {
            mdbBreadcrumb,
            mdbBreadcrumbItem,
            mdbContainer,
            mdbRow,
            mdbIcon,
            mdbCard,
            mdbCardImage,
            mdbCardBody,
            mdbCardTitle,
            mdbCardText,
            mdbView,
            mdbMask,
            mdbCol,
            mdbBtn,
            mdbCardGroup,
            vueDropzone: vue2Dropzone,
        },
        data() {
            return {
                paso : 1,
                show: false,
                token : localStorage.getItem('token'),
                seleccionados: [],
                activo: false,
                delegaciones: [],
                fields_usuarios: [
                    {key: 'id', label: 'Id', sortable: true },
                    {key: 'username', label: 'Nombre Usuario', sortable: true},
                    {key: 'email', label: 'Email', sortable: true},
                    {key: 'role', label: 'Rol', sortable: true},
                    {key: 'active', label: 'Activado', sortable: true},
                    {key: 'created_at', label: 'Fecha creación', sortable: true, formatter (value) {return  moment(String(value)).format('DD/MM/YYYY HH:mm:ss')} },
                ],
                video : {
                    id: '',
                    nombre: '',
                    descripcion: '',
                    imagen: '',
                    estado: 'DRAFT',
                    video: '',
                    delegacion: 1,
                    user_id: localStorage.getItem('user_id'),
                },
                esta_en_vertical: false,
                pagina_actual : 1,
                is_mobile:'',
                img_upload_title : '',
                img_upload: '',
                video_upload: '',
                file: '',
                video_upload_title:'',
                dropzoneOptions: {
                    url: 'https://api.agustinos.tv/api/rtmp/ok',
                    thumbnailWidth: 150,
                    maxFilesize: 500,
                    maxFiles: 1,
                    headers: { "My-Awesome-Header": "header value" }
                },
                dropzoneOptions2: {
                    url: '#',
                    thumbnailWidth: 150,
                    maxFilesize: 50000,
                    maxFiles: 1,
                    headers: { "My-Awesome-Header": "header value" }
                },
            }
        },
        methods: {
            IrPaso2()
            {

                ApiService.createVideo(this.video,this.img_upload_title,this.img_upload).then( response => {
                    this.video = response.data;
                    console.log('video');
                    console.log(this.video);
                    this.paso = 2
                    console.log(this.img_upload);
                });

            },

            uploadImage(e)
            {
                console.log(e);
                this.img_upload_title = e.name;
                console.log(this.img_upload_title);
                const reader = new FileReader();
                reader.readAsDataURL(e);
                reader.onload = e => {
                    this.img_upload = e.target.result;

                };
            },
            SubirVideo(e)
            {
                this.video_upload_title = this.file.name;
                let formData = new FormData();

                formData.append('file', this.file);
                ApiService.uploadVideo(this.video.id,this.video_upload_title,formData).then(r => {
                    console.log(r);
                    this.$swal.fire({
                        title: 'Vídeo subido con éxito',
                        text: 'Ha sido añadido a la cola de codificación y será notificado por telegram cuando haya finalizado',
                        type: 'success',
                        showCancelButton: false,
                    });
                    this.$router.push({path: '/admin/videos'});
                }).catch(function(){

                    console.log('FAILURE!!');

                });

            },
            onChangeFileUpload(e)
            {
                this.file = e;
                this.SubirVideo(e);
            },
            VolverPanel()
            {
                this.$router.push({path: '/admin'});
            },
            IrDelegacion()
            {
                this.$router.push({path: '/admin/videos'});
            },

            FechaSQLtoFechaES(entrada)
            {
                return  moment(String(entrada)).format('DD/MM/YYYY hh:mm:ss');
            },
            //Ordenar las novedades de más reciete a anterior (Son distintos tipos de objetos)
            compare(a, b) {

                var dateA = moment(a.created_at, "YYYY-MM-DD"); // replace format by your one
                var dateB = moment(b.created_at, "YYYY-MM-DD");

                if(dateA.diff(dateB) > 0) {
                    return -1;
                }
                if(dateB.diff(dateA) > 0) {
                    return 1;
                }
                return 0;

            },
            ReloadData()
            {
                ApiService.getDelegaciones(null,'DESC',1000).then(r => {
                    this.delegaciones = r.data.data;
                    console.log('delegaciones');
                    console.log(this.delegaciones);
                })


            },

        },
        mounted(){
            ApiService.getMe().then(r => {
                if(r.data.role != 'ROOT' && r.data.role != 'ADMIN' && r.data.role != 'SUPERVISOR')
                {
                    this.$swal.fire({
                        title: 'Acceso no autorizado',
                        text: 'No lo intentes, de aquí en adelante no tienes acceso',
                        type: 'error',
                        showCancelButton: false,
                    });
                    this.$router.push({path: '/'});
                }
                else {
                    this.show = true;
                }

            });

        },
        created(){


            ApiService.isMobile().then(response => {
                console.log('isMobile');
                console.log(response);
                this.is_mobile = response.data.is_mobile;
                if(localStorage.getItem('is_mobile'))
                {

                    this.is_mobile = localStorage.getItem('is_mobile');
                    console.log('leyendo is_mobile : ' + this.is_mobile );
                }

                else {
                    console.log('asignando is_mobile = ' + this.is_mobile);
                    localStorage.setItem('is_mobile', this.is_mobile);
                    if(this.is_mobile == true) {
                        localStorage.setItem('res_video', '360p');
                    }
                    else {
                        localStorage.setItem('res_video', '720p');
                    }
                    this.$forceUpdate();
                }
            });

            this.ReloadData();
        },
        computed: {

        }

    }


</script>

<style>
    html {
        scroll-behavior: smooth;
    }
</style>
